<template>
  <div class="flex items-center">
    <img :src="photoUrl" class="flex-shrink-0 mr-2 rounded-lg" width="30px" @click="$router.push(url)" />
    <router-link :to="url" @click.stop.prevent class="text-inherit hover:text-primary text-lg font-bold">{{ params.value }}</router-link>
  </div>
</template>

<script>
export default {
  computed: {
    url() {
      return `/a/study/sets/${this.params.data.id}`;
    },
    photoUrl() {
      return this.params.data.thumbnail !== null
        ? this.params.data.thumbnail
        : 'https://connect-cdn.intellectualpoint.com/assets/images/misc/default-placeholder.png';
    },
  },
};
</script>
